import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0cf6ffd22cd2445c05465c9a8e1a3a3a@o1372643.ingest.us.sentry.io/4507924721041408",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});